.side-bar {
    height: 100vh;
    display: flex;
    flex-direction: column;
    gap: var(--space-30);
    z-index: 1;
    box-shadow: var(--shadow-m);
}

.side-bar__logo-wrapper {
    padding: var(--space-30) var(--space-16) 0 var(--space-16);
}
.side-bar__link-group-title {
    color: var(--jaguar-500);
    padding-left: var(--space-16);
    margin-bottom: var(--space-12);
}

.side-bar__link-item {
    height: 4.8rem;
}

.side-bar__link {
    font-size: var(--font-size-16);
    font-family: inherit;
    font-weight: var(--font-weight-500);
    line-height: var(--line-height-24);
    color: var(--jaguar-900);
    height: 100%;

    display: flex;
    align-items: center;
    gap: var(--space-12);
}

.side-bar__link.active,
.side-bar__link:hover {
    color: var(--primary-500);
    background-color: var(--primary-12);
}

.side-bar__link-icon {
    fill: var(--jaguar-500);
    margin-left: var(--space-4);
}

.side-bar__link.active .side-bar__link-icon,
.side-bar__link:hover .side-bar__link-icon {
    fill: var(--primary-500);
}

.side-bar__link-item .side-bar__link::before {
    content: "";
    display: block;
    width: 0.3rem;
    height: 2.4rem;
    background-color: transparent;
    border-top-right-radius: var(--border-radius-4);
    border-bottom-right-radius: var(--border-radius-4);
}

.side-bar__link.active::before {
    background-color: var(--primary-500);
}

.side-bar__log-out-wrapper {
    height: 4.8rem;
    padding-left: var(--space-16);
    display: flex;
    border-top: 1px solid var(--jaguar-100);
    margin-top: auto;
}

.side-bar__log-out {
    cursor: pointer;
    height: 5rem;
    display: flex;
    align-items: center;
    gap: var(--space-12);
}

.log-out-icon {
    fill: var(--red-orange-500);
}

.side-bar__log-out-link {
    font-size: var(--font-size-16);
    font-family: inherit;
    font-weight: var(--font-weight-500);
    line-height: var(--line-height-24);
    color: var(--jaguar-900);
}
