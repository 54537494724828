:root {
    --font-weight-400: 400;
    --font-weight-500: 500;
    --font-weight-600: 600;
    --font-weight-700: 700;

    --font-size-10: 1rem;
    --font-size-12: 1.2rem;
    --font-size-13: 1.3rem;
    --font-size-14: 1.4rem;
    --font-size-16: 1.6rem;
    --font-size-18: 1.8rem;
    --font-size-20: 2rem;
    --font-size-24: 2.4rem;
    --font-size-32: 3.2rem;
    --font-size-40: 4rem;
    --font-size-48: 4.8rem;
    --font-size-56: 5.6rem;
    --font-size-64: 6.4rem;
    --font-size-72: 7.2rem;
    --font-size-80: 8rem;

    --line-height-16: 1.6rem;
    --line-height-18: 1.8rem;
    --line-height-20: 2rem;
    --line-height-24: 2.4rem;
    --line-height-26: 2.6rem;
    --line-height-28: 2.8rem;
    --line-height-32: 3.2rem;
    --line-height-40: 4rem;
    --line-height-48: 4.8rem;
    --line-height-56: 5.6rem;
    --line-height-64: 6.4rem;
    --line-height-72: 7.2rem;
    --line-height-88: 8.8rem;
    --line-height-96: 9.6rem;
}

.display-lg,
.display-sm,
.heading-1,
.heading-2,
.heading-3,
.heading-4,
.heading-5,
.heading-6 {
    color: var(--black-900);
}

.display-lg {
    font-size: var(--font-size-80);
    line-height: var(--line-height-96);
}

.display-sm {
    font-size: var(--font-size-72);
    line-height: var(--line-height-80);
}

.heading-1 {
    font-size: var(--font-size-64);
    line-height: var(--line-height-72);
}

.heading-2 {
    font-size: var(--font-size-56);
    line-height: var(--line-height-64);
}

.heading-3 {
    font-size: var(--font-size-48);
    line-height: var(--line-height-56);
}

.heading-4 {
    font-size: var(--font-size-40);
    line-height: var(--line-height-48);
}

.heading-5 {
    font-size: var(--font-size-32);
    line-height: var(--line-height-40);
}

.heading-6 {
    font-size: var(--font-size-24);
    line-height: var(--line-height-32);
}

.paragraph-lg,
.paragraph-md,
.paragraph-sm,
.subtitle-lg,
.subtitle-md,
.subtitle-sm,
.button-lowercase,
.button-uppercase,
.overline {
    color: var(--jaguar-900); /* Replace 'blue' with your desired color */
}

.paragraph-lg {
    font-size: var(--font-size-20);
    line-height: var(--line-height-28);
}

.paragraph-md {
    font-size: var(--font-size-18);
    line-height: var(--line-height-26);
}

.paragraph-sm {
    font-size: var(--font-size-16);
    line-height: var(--line-height-24);
}

.subtitle-lg {
    font-size: var(--font-size-14);
    line-height: var(--line-height-20);
}

.subtitle-md {
    font-size: var(--font-size-13);
    line-height: var(--line-height-20);
}

.subtitle-sm {
    font-size: var(--font-size-12);
    line-height: var(--line-height-18);
}

.overline {
    font-size: var(--font-size-10);
    line-height: var(--line-height-16);
}

.font-weight-normal {
    font-weight: var(--font-weight-400);
}

.font-weight-medium {
    font-weight: var(--font-weight-500);
}

.font-weight-semibold {
    font-weight: var(--font-weight-600);
}

.font-weight-bold {
    font-weight: var(--font-weight-700);
}

.text-align-center {
    text-align: center;
}

.text-align-left {
    text-align: left;
}
.text-align-right {
    text-align: right;
}
.text-align-justify {
    text-align: justify;
}
.text-align-inherit {
    text-align: inherit;
}
