.logo {
    display: flex;
    width: max-content;
    align-content: center;
    cursor: pointer;
}

.logo.logo-small {
    height: 4rem;
}

.logo__text {
    color: var(--black-900);
    font-weight: var(--font-weight-700);
}

.logo-vertical {
    flex-direction: column;
    gap: var(--space-20);
}

.logo-horizontal {
    flex-direction: row;
    gap: var(--space-20);
    align-items: center;
}

.logo-large img {
    height: 6rem;
    width: 6.3rem;
    margin: 0 auto;
}

.logo-large .logo__text {
    font-size: var(--font-size-32);
    line-height: var(--line-heigh-40);
}

.logo-small img {
    height: 3rem;
    width: 3.2rem;
}

@media screen and (min-width: 850px) {
    .logo-small img {
        height: 4rem;
        width: 4.2rem;
    }
}

.logo-small .logo__text {
    font-size: var(--font-size-20);
    line-height: var(--line-height-28);
    height: 4rem;

    display: flex;
    align-items: center;
}

@media screen and (min-width: 850px) {
    .logo-small .logo__text {
        font-size: var(--font-size-24);
    }
}
