.input {
    outline: none !important;
    height: 3.6rem;
    border: 0.15rem solid var(--jaguar-200);
    padding: 0 var(--space-16);
    font-family: inherit;
    font-size: var(--font-size-16);
    color: var(--jaguar-900);
    width: 100%;
    transition: all 0.3s;
}

input::placeholder,
:-ms-input-placeholder {
    color: var(--jaguar-400);
}

.input-small {
    height: 3.6rem;
}

.input-medium {
    height: 4rem;
}

.input-large {
    height: 4.8rem;
}

/* .input-large.input-rounded {
    border-radius: var(--border-radius-16);
} */

.input-rounded {
    border-radius: var(--border-radius-8);
}

.input-circle {
    border-radius: var(--border-radius-20);
}

.input-lg.input-circle {
    border-radius: var(--border-radius-24);
}

.input:hover:not(.input-error, :disabled) {
    border: 0.15rem solid var(--primary-500);
}

.input:focus:not(.input-error) {
    border: 0.15rem solid var(--primary-500);
}

.input-error {
    border: 0.15rem solid var(--red-orange-500);
}

.input:disabled {
    border-color: var(--jaguar-100);
    background-color: var(--white);
}

.input:disabled::placeholder {
    color: var(--jaguar-300);
}

.input-wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--space-4);
    position: relative;
}

.input-wrapper label {
    display: block;
}

.input-textarea {
    resize: none;
    padding-top: var(--space-16);
    padding-bottom: var(--space-16);
}

.input-textarea.input-small {
    height: 7.2rem;
}

.input-textarea.input-medium {
    height: 12rem;
}

.input-textarea.input-large {
    height: 13.4rem;
}

.hint-message {
    font-size: var(--font-size-12);
    line-height: var(--line-height-18);
    color: var(--jaguar-500);
    font-weight: var(--font-weight-400);
}

.hint-message--error {
    color: var(--red-orange-500);
}
