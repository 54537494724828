.menu {
    position: relative;
}

.menu__default-trigger {
    cursor: pointer;
    fill: var(--jaguar-400);
}

.menu__options {
    background-color: var(--white);
    border-radius: var(--border-radius-16);
    box-shadow: var(--shadow-s-hover);
    position: absolute;
    top: 2.4rem;
    right: 0;
    min-width: 15rem;
    width: max-content;
    list-style-type: none;
    z-index: 2;
}

.menu__option {
    height: 4.8rem;
    padding: 0 var(--space-16) 0 var(--space-8);
    display: flex;
    align-items: center;
    gap: var(--space-4);
    cursor: pointer;
    font-size: var(--font-size-16);
    line-height: var(--line-height-24);
    font-weight: var(--font-weight-500);
    color: var(--jaguar-600);
}

.menu__option svg {
    fill: var(--jaguar-600);
    height: 2rem;
    width: 2rem;
}

.menu__option:not(:last-child) {
    border-bottom: 1px solid var(--jaguar-100);
}

.menu__option:first-child {
    border-top-right-radius: var(--border-radius-16);
    border-top-left-radius: var(--border-radius-16);
}

.menu__option:last-child {
    border-bottom-right-radius: var(--border-radius-16);
    border-bottom-left-radius: var(--border-radius-16);
}

.menu__option:hover {
    background-color: var(--jaguar-50);
}

.menu__option.menu__option--danger {
    color: var(--red-orange-500);
}

.menu__option.menu__option--danger svg {
    fill: var(--red-orange-500);
}

.menu__option.menu__option--primary {
    color: var(--primary-500);
}

.menu__option.menu__option--primary svg {
    fill: var(--primary-500);
}
