/*Visually Removes default checkbox  */
.checkbox__hidden-input {
    appearance: none;
    opacity: 0;
    position: absolute;
}

/* Custom Checkbox */
.checkbox__custom {
    position: relative;
    height: 2rem;
    width: 2rem;
    border: 0.15rem solid var(--jaguar-400);
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkbox__custom--checked {
    border: none;
    background-color: var(--primary-500);
}

.checkbox__custom.checkbox__custom--rounded {
    border-radius: 0.5rem;
}

.checkbox__custom.checkbox__custom--circle {
    border-radius: 0.8rem;
}

/* Icon */
.checkbox__icon {
    fill: var(--white);
    width: 1.8rem;
    height: 1.8rem;
}

/* Label */
.checkbox__label {
    color: var(--jaguar-900);
    display: flex;
    align-items: center;

    gap: var(--space-16);
    color: var(--jaguar-900);
    cursor: pointer;
    height: 4rem;
}

.checkbox__label.checkbox__label--end .checkbox__custom {
    order: 1;
}

/* Disabled State */
.checkbox__custom--disabled {
    border-color: var(--jaguar-200);
}

.checkbox__custom--checked.checkbox__custom--disabled {
    background-color: var(--primary-200);
}

/* Hover and Focus state */

.checkbox__hidden-input:hover:not(:focus) + .checkbox__custom {
    border-color: var(--primary-500);
}

.checkbox__hidden-input:focus + .checkbox__custom {
    box-shadow: 0px 0px 0px 0.4rem var(--primary-50);
}
