:root {
    --primary-900: #00d;
    --primary-800: #2c0ee3;
    --primary-700: #4617e8;
    --primary-600: #5b21f0;
    --primary-500: #6826f7;
    --primary-400: #844efa;
    --primary-300: #9e70fb;
    --primary-200: #bb9cfb;
    --primary-100: #d7c4fc;
    --primary-50: #f0e7fe;
    --primary-25: #f7f3fe;
    --primary-12: #fbf9ff;

    --sunglow-900: #f96e16;
    --sunglow-800: #fa8c18;
    --sunglow-700: #fb9d19;
    --sunglow-600: #fcaf1a;
    --sunglow-500: #fcbd1f;
    --sunglow-400: #fdc632;
    --sunglow-300: #fed254;
    --sunglow-200: #fede84;
    --sunglow-100: #ffebb4;
    --sunglow-50: #fff7e1;
    --sunglow-25: #fffbf0;
    --sunglow-12: #fffdf8;

    --jaguar-900: #080317;
    --jaguar-800: #2a2836;
    --jaguar-700: #474555;
    --jaguar-600: #5a5768;
    --jaguar-500: #817d8f;
    --jaguar-400: #a29fb2;
    --jaguar-300: #c8c5d8;
    --jaguar-200: #ddd9ed;
    --jaguar-100: #ece8fc;
    --jaguar-50: #f7f3ff;
    --jaguar-25: #fbf9ff;
    --jaguar-12: #fdfcff;

    --dodger-blue-900: #2842c9;
    --dodger-blue-800: #2165e8;
    --dodger-blue-700: #1977fb;
    --dodger-blue-600: #048bff;
    --dodger-blue-500: #009aff;
    --dodger-blue-400: #28a9ff;
    --dodger-blue-300: #58baff;
    --dodger-blue-200: #8dceff;
    --dodger-blue-100: #bbe0ff;
    --dodger-blue-50: #e3f3ff;
    --dodger-blue-25: #f1f9ff;
    --dodger-blue-12: #f8fcff;

    --green-900: #006500;
    --green-800: #008400;
    --green-700: #00950e;
    --green-600: #00a71e;
    --green-500: #00b627;
    --green-400: #46c24e;
    --green-300: #6dcd6f;
    --green-200: #9ada9a;
    --green-100: #c2e9c1;
    --green-50: #e6f6e6;
    --green-25: #f2faf2;
    --green-12: #f9fdf9;

    --light-green-900: #009b00;
    --light-green-800: #00c000;
    --light-green-700: #00d400;
    --light-green-600: #28ea00;
    --light-green-500: #48fd00;
    --light-green-400: #74ff03;
    --light-green-300: #99ff59;
    --light-green-200: #bcff91;
    --light-green-100: #d9ffbe;
    --light-green-50: #f0ffe5;
    --light-green-25: #f7fff2;
    --light-green-12: #fbfff9;

    --black-900: #000;
    --black-800: #262626;
    --black-700: #434343;
    --black-600: #555;
    --black-500: #7b7b7b;
    --black-400: #9d9d9d;
    --black-300: #c4c4c4;
    --black-200: #d9d9d9;
    --black-100: #e9e9e9;
    --black-50: #f5f5f5;
    --black-25: #fafafa;
    --black-12: #fdfdfd;

    --red-orange-900: #bf1817;
    --red-orange-800: #cf2524;
    --red-orange-700: #db2d2b;
    --red-orange-600: #ee3831;
    --red-orange-500: #fc4130;
    --red-orange-400: #f7534d;
    --red-orange-300: #ec7372;
    --red-orange-200: #f59b99;
    --red-orange-100: #ffcdd2;
    --red-orange-50: #ffebee;
    --red-orange-25: #fff5f6;
    --red-orange-12: #fffafb;

    --rose-900: #9c0056;
    --rose-800: #c2005b;
    --rose-700: #d7005d;
    --rose-600: #ed0061;
    --rose-500: #ff0064;
    --rose-400: #ff197d;
    --rose-300: #ff5496;
    --rose-200: #ff89b4;
    --rose-100: #ffb9d2;
    --rose-50: #ffe3ed;
    --rose-25: #fff1f6;
    --rose-12: #fff8fb;

    --purple-900: #4c00df;
    --purple-800: #8300ea;
    --purple-700: #9b00ed;
    --purple-600: #b600f1;
    --purple-500: #c900f6;
    --purple-400: #d400f9;
    --purple-300: #dd56fb;
    --purple-200: #e88dfb;
    --purple-100: #f1bcfc;
    --purple-50: #fae5fe;
    --purple-25: #fcf2fe;
    --purple-12: #fef9ff;

    --blue-ribbon-900: #0000c2;
    --blue-ribbon-800: #0025d2;
    --blue-ribbon-700: #0032dd;
    --blue-ribbon-600: #003ee9;
    --blue-ribbon-500: #0247f5;
    --blue-ribbon-400: #4965f9;
    --blue-ribbon-300: #7083fb;
    --blue-ribbon-200: #9da6fb;
    --blue-ribbon-100: #c6c9fd;
    --blue-ribbon-50: #e8eaff;
    --blue-ribbon-25: #f3f4ff;
    --blue-ribbon-12: #f9faff;

    --blue-charcoal-900: #00040f;
    --blue-charcoal-800: #26282f;
    --blue-charcoal-700: #43464d;
    --blue-charcoal-600: #55585f;
    --blue-charcoal-500: #7b7e86;
    --blue-charcoal-400: #9da0a9;
    --blue-charcoal-300: #c3c6cf;
    --blue-charcoal-200: #d8dbe4;
    --blue-charcoal-100: #e6eaf3;
    --blue-charcoal-50: #f2f5ff;
    --blue-charcoal-25: #f8faff;
    --blue-charcoal-12: #fcfdff;

    --black: #000000;
    --white: #ffffff;
    --transparent: transparent;
    --overlay-40: rgba(0, 0, 0, 0.4);
}
